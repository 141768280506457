export enum Methods {
    MAIN_REQUEST = 'Main/ApiRequest',
    LOGIN_USER = 'Main/LoginUser',
    CHANGE_PASSWORD = 'Main/ChangePassword',
    CHANGE_LANGUAGE = 'Main/ChangeLanguage',
    PARTNER_ADMIN_CONFIG = 'Main/GetPartnerAdminConfig',
    UPDATE_BET_SETTINGS = 'Main/CreateWinOrLostOrRollbackDocumentFromFile',
    HEALTH_CHECK = 'HealthCheck',
    GET_TRANSLATIONS = 'Translation/get-translations-by-parent-id',
    GET_LANGUAGES = 'translation/get-languages',
    ADD_LANGUAGE = 'translation/add-language',
    UPDATE_LANGUAGE = 'translation/update-language',
    REMOVE_LANGUAGE = 'translation/remove-language',
    GET_LANGUAGE_STATES = 'translation/get-language-states',
    CHANGE_LANGUAGE_STATUS = 'translation/change-language-status',
    GET_PARTNER_LANGUAGE_SETTINGS = 'translation/get-partner-language-settings',
    GET_LANGUAGES_BY_PARTNER_ID_AND_STATE = 'translation/get-languages-by-partnerid-and-state',
    CHANGE_PARTNER_LANGUAGE_STATUS = 'translation/change-partner-language-status',
    ADD_TRANSLATION = 'translation/add',
    UPDATE_TRANSLATION_JSON = 'translation/upload-json-files',
    EDIT_TRANSLATION = 'translation/update-translation',
    REMOVE_TRANSLATION = 'translation/remove-translation',
    GET_PROJECT_TYPES = 'translation/get-project-types',
    REQUEST_WITH_IMAGE_FILE = 'Main/ApiRequestWithImageFile',
    BANNER_PARTNER_BANNERS = 'banner/partner-banners',
    BANNER_GET_ALL = 'banner/get-all',
    BANNER_STATUSES = 'banner/banner-statuses',
    BANNER_TYPES = 'banner/banner-types',
    BANNER_VISIBILITY = 'banner/banner-visibility',
    BANNER_DEVICE_TYPES = 'banner/device-types',
    BANNER_MODE = 'banner/mode',
    BANNER_AVAILABLE_PAGE_TYPES = 'banner/available-page-types',
    ADD_BANNER = 'banner/add',
    UPDATE_BANNER = 'banner/update',
    UPDATE_BANNER_STATUS = 'banner/change-banner-status',
    UPDATE_BANNER_ORDER = 'banner/change-banner-order',
    DELETE_BANNER = 'banner/delete',
    BUTTONS_STRUCTURE = 'banner/buttons-structure',
    BUTTONS_POSITIONS = 'banner/buttons-positions',
    GET_DEVICE_REPORT = 'devicedetection/DeviceDetectionReports/GetDeviceReport',
    GET_CLIENT_DEVICE_REPORT = 'devicedetection/DeviceDetectionReports/GetClientDeviceReport',
    GET_MAD_REPORT = 'devicedetection/DeviceDetectionReports/GetMADReport',
    GET_CLIENT_DEVICE_DETECTION_INFO = 'devicedetection/DeviceDetectionReports/GetClientInfo',
    UPDATE_DEVICE_COMMENT = 'devicedetection/DeviceDetectionReports/UpdateDeviceComment',
    BLACK_LIST = 'BlackList',
    GET_BLACKLIST_TYPES_ENUM = 'BlackList/GetBlackListTypesEnum',
    GET_BLACKLIST = 'BlackList/GetBlackList',
    ADD_TO_BLACKLIST = 'BlackList/CreateBlackListItem',
    EDIT_BLACKLIST_ITEM = 'BlackList/UpdateBlackListItem',
    UNBLOCK_BLACKLIST_ITEM = 'BlackList/DeleteBlackListItem',
    FILE_UPLOAD = 'file/upload',
    GET_PROMOTIONS_MENU = 'PromotionMenu/get-grouped',
    ADD_PROMOTIONS_MENU = 'PromotionMenu/add',
    UPDATE_PROMOTIONS_MENU = 'PromotionMenu/update',
    UPDATE_PROMOTIONS_MENU_STATUS = 'PromotionMenu/update-status',
    DELETE_PROMOTION_MENU = 'PromotionMenu/remove',
    GET_PROMOTIONS = 'Promotion/get-grouped',
    ADD_PROMOTION = 'Promotion/add',
    UPDATE_PROMOTION = 'Promotion/update',
    UPDATE_PROMOTION_STATUS = 'Promotion/update-status',
    DELETE_PROMOTION = 'Promotion/remove',
    GET_CMS_ENUM = 'enum/',
    READ_ROUTE_PAGES = 'routepage/get-all/',
    DELETE_ROUTE_PAGE = 'routepage/delete/',
    CHANGE_ROUTE_PAGE_STATUS = 'routepage/change-status',
    COPY_ROUTE_PAGE_FROM_PARTNER = 'routepage/copy-from-partner',
    CREATE_ROUTE_PAGE = 'routepage/add',
    GET_PARTNER_DOMAIN = 'PartnerDomain/GetPartnerDomains',
    CREATE_PARTNER_DOMAIN = 'PartnerDomain/CreatePartnerDomain',
    UPDATE_PARTNER_DOMAIN = 'PartnerDomain/UpdatePartnerDomain',
    DELETE_PARTNER_DOMAIN = 'PartnerDomain/DeletePartnerDomain',
    GET_PARTNER_DOMAIN_TYPES = 'PartnerDomain/GetPartnerDomainTypes',
    GET_PARTNER_DOMAIN_SEGMENTS = 'PartnerDomain/GetPartnerDomainSegments',
    ASSETS_GET_All = 'assets/get-all',
    ADD_FILE = 'assets/add-file',
    ADD_FOLDER = 'assets/add-folder',
    UPDATE_FOLDER = 'assets/update-folder',
    DELETE_FOLDER = 'assets/remove-folder',
    UPDATE_FILE = 'assets/update-file',
    DELETE_FILE = 'assets/remove-file',
    COPY_FROM_PARTNER = 'assets/copy-from-partner',
    GET_BLOG_MENUS = 'blogmenu/getall',
    ADD_BLOG_MENU = 'blogmenu/add',
    UPDATE_BLOG_MENU = 'blogmenu/update',
    UPDATE_BLOG_MENU_STATUS = 'blogmenu/update-status',
    DELETE_BLOG_MENU = 'blogmenu/remove',
    GET_BLOGS = 'blog/get-grouped',
    ADD_BLOG = 'blog/add',
    UPDATE_BLOG = 'blog/update',
    UPDATE_BLOG_STATUS = 'blog/update-status',
    DELETE_BLOG = 'blog/remove',
    GET_PARTNER_TRANSLATION_KEYS = 'translation/get-parent-translation-keys',
    COPY_TRANSLATIONS_FROM_PARTNER = 'translation/copy-from-partner',
    GET_PENDING_COPY_PARTNER_TRANSLATIONS = 'translation/get-pending-copy-partner-translations',
    GET_TRANSLATION_SUB_KEYS = 'common/get-translation-sub-keys-by-parent',
    GET_BUTTON_TYPES = 'common/get-button-types',
    GET_REDIRECTS = 'seo/get-all-redirects',
    ADD_REDIRECT = 'seo/add-redirect',
    UPDATE_REDIRECT = 'seo/update-redirect',
    SEO_SYNC_FILE = 'seo/sync-file',
    DELETE_REDIRECT = 'seo/removeredirect',
    GET_SITEMAPS = 'seo/get-all-sitemaps',
    GET_SITEMAP = 'seo/getsitemap',
    ADD_SITEMAP = 'seo/add-sitemap',
    UPDATE_SITEMAP = 'seo/update-sitemap',
    DELETE_SITEMAP = 'seo/remove-sitemap',
    GET_TEXT_FILE = 'seo/gettextfile',
    UPDATE_TEXT_FILE = 'seo/update-textfile',
    GENERATE_DEFAULT_SITEMAP = 'seo/create-default-sitemap',
    ADD_STYLE_MODE = 'stylemode/add',
    ADD_STYLE_SCHEME = 'stylemode/add-scheme',
    REMOVE_STYLE_MODE = 'stylemode/delete',
    REMOVE_STYLE_SCHEME = 'stylemode/delete-scheme',
    UPDATE_STYLE_MODE = 'stylemode/update',
    UPDATE_STYLE_SCHEME = 'stylemode/update-scheme',
    GET_ALL_STYLES = 'stylemode/get-all',
    COPY_FROM_PARTNER_STYLE_MODES = 'stylemode/copy-from-partner',
    GET_DROPDOWN_STYLE_MODES = 'stylemode/get-all-for-dropdown',
    SYNC_STYLE_MODES = 'stylemode/sync',
    UPDATE_NAVIGATION_STATUS = 'block/change-status',
    UPDATE_NAVIGATION_ITEM_STATUS = 'block/change-block-item-status',
    GET_NAVIGATIONS = 'block/get-blocks',
    GET_NAVIGATION_ITEMS = 'block/get-block-items',
    NAVIGATION_PAGE_TYPES = 'block/get-block-route-pages',
    NAVIGATION_TYPES = 'block/get-block-type',
    NAVIGATION_ITEM_TYPES = 'block/get-block-item-type',
    ADD_NAVIGATION = 'block/add-block',
    ADD_NAVIGATION_ITEM = 'block/add-block-item',
    UPDATE_NAVIGATION = 'block/update-block',
    UPDATE_NAVIGATION_ITEM = 'block/update-block-item',
    DELETE_NAVIGATION = 'block/delete',
    DELETE_NAVIGATION_ITEM = 'block/delete-block-item',
    COPY_NAVIGATION_FROM_PARTNER = 'block/copy-from-partner',
    GET_SEO_PAGES = 'pageseo/get-all-pages',
    ADD_SEO_PAGE = 'pageseo/add-page',
    UPDATE_SEO_PAGE_STATUS = 'pageseo/update-status',
    DELETE_SEO_PAGE = 'pageseo/remove',
    GET_SEO_PAGE_DATA = 'pageseo/get-page-seo-data',
    UPDATE_SEO_PAGE_DATA = 'pageseo/update-page-seo-data',
    GET_SEO_PAGE_SCHEME = 'pageseo/get-page-seo-scheme',
    UPDATE_SEO_PAGE_SCHEME = 'pageseo/update-page-seo-scheme',
    GET_SEO_PAGE_CANONICALIZATION = 'pageseo/get-page-seo-canonicalization',
    UPDATE_SEO_PAGE_CANONICALIZATION = 'pageseo/update-page-seo-canonicalization',
    GET_FILES_FOR_DROPDOWN = 'assets/get-files-for-dropdown',
    GET_SEO_GAMES = 'gameseo/get-all-games',
    UPDATE_SEO_GAME_DATA = 'gameseo/update-game-seo-data',
    GET_SEO_GAME_DATA = 'gameseo/get-game-seo-data',
    GET_SEO_GAME_SCHEME = 'gameseo/get-game-seo-scheme',
    UPDATE_SEO_GAME_SCHEME = 'gameseo/update-game-seo-scheme',
    NAVIGATION_COPY_PAGE_TYPES = 'block/get-included-route-pages',
    GET_NAVIGATION_BLOCK_TYPES = 'block/get-block-types-by-route-pages',
    COPY_NAVIGATION_PROM_PAGE = 'block/copy-from-page',
    UPLOAD_IMAGE_FROM_FORM_DATA = 'main/UploadImageFromFormData',
    GET_REGISTRATIONS = 'registration/get-all',
    ADD_REGISTRATION = 'registration/add',
    UPDATE_REGISTRATION = 'registration/update',
    DELETE_REGISTRATION = 'registration/remove',
    CHANGE_REGISTRATION_STATUS = 'registration/update-status',
    GET_REGISTRATION_COUNTRIES = 'registration/get-countries',
    CREATE_FOOTER_ITEM = 'FooterContent/add',
    GET_ALL_FOOTER_ITEMS = 'FooterContent/get-all',
    DELETE_FOOTER_ITEM = 'FooterContent/remove',
    UPDATE_FOOTER_ITEM = 'FooterContent/update',
    UPDATE_FOOTER_ITEM_STATUS = 'FooterContent/update-status',
    GET_FOOTER_ITEM = 'FooterContent/get-footer-item',
    SYNC_FOOTER_ITEMS = 'FooterContent/sync',
    ORDER_FOOTER_ITEMS = 'FooterContent/order',
    GET_FOOTER_CONTENT_LANGUAGES = 'FooterContent/get-content-languages',
    GET_FOOTER_ITEM_CONTENT = 'FooterContent/get-content',
    GET_FOOTER_ITEM_CONTENT_TREE = 'FooterContent/get-content-tree',
    UPDATE_FOOTER_CONTENT_ITEM = 'FooterContent/updatecontent',
    GET_FOOTER_CONTENT_BY_ID = 'FooterContent/get-by-id',
    ADD_PAGE_404 = 'page404/add',
    UPDATE_PAGE_404 = 'page404/update',
    DELETE_PAGE_404 = 'page404/delete/',
    GET_PAGE_404 = 'page404/get/',
    GET_ALL_COUNTRIES = 'region/getallcountries',
    ADD_COUNTRY = 'region/addcountry',
    UPDATE_COUNTRY = 'region/updatecountry',
    UPDATE_COUNTRY_STATUSES = 'region/updatecountrystatuses',
    UPLOAD_COUNTRIES = 'region/uploadcountries',
    DELETE_COUNTRY = 'region/deletecountry',
    GET_ALL_DISTRICTS = 'region/getalldistricts',
    ADD_DISTRICT = 'region/adddistrict',
    UPDATE_DISTRICT = 'region/updatedistrict',
    UPDATE_DISTRICT_STATUS = 'region/updatedistrictstatus',
    UPLOAD_DISTRICTS = 'region/uploaddistricts',
    DELETE_DISTRICT = 'region/deletedistrict',
    GET_ALL_CITIES = 'region/getallcities',
    ADD_CITY = 'region/addcity',
    UPDATE_CITY = 'region/updatecity',
    UPLOAD_CITIES = 'region/uploadcities',
    DELETE_CITY = 'region/deletecity',
    UPDATE_ROUTE_PAGE = 'routepage/update',
    SPORT_GET_ALL = 'sportsBook/get-matches',
    DELETE_SPORT = 'sportsBook/remove-match',
    GET_SPORTSBOOK_SPORT_NAMES = 'sportsbook/get-sport-names',
    GET_SPORTSBOOK_COUNTRIES = 'sportsbook/get-countries',
    GET_SPORTSBOOK_COMPETITIONS = 'sportsbook/get-competitions',
    GET_SPORTSBOOK_MATCHES = 'sportsbook/get-matches',
    ADD_SPORTSBOOK_MATCHES = 'sportsbook/add-matches',
    GET_ALL_PARTNER_COUNTRIES = 'PartnerCountry/getall',
    UPDATE_PARTNER_COUNTRY = 'PartnerCountry/update',
    DELETE_PARTNER_COUNTRY = 'PartnerCountry/delete',
    ADD_PARTNER_COUNTRY = 'PartnerCountry/add',
    GET_DROPDOWN_COUNTRIES = 'PartnerCountry/getdropdown',
    GET_KEYS = 'partnerkey/get-all',
    DELETE_KEY = 'partnerkey/delete/',
    CREATE_KEY = 'partnerkey/add-key',
    UPDATE_KEY = 'partnerkey/update-key',
    GET_KEY_NAMES = 'partnerkey/get-partner-key-names/',
    GET_BANNER_BY_ID = 'banner/get-by-id'
}
