import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
    ICMSLanguage,
    ILanguage,
    IPartnerLanguages,
    IResponseData,
} from '@core/interfaces';
import { environment } from '@env/environment';
import { Methods, StatesEnum } from '@core/enums';
import { HttpService, ToastService, AuthService } from '@core/services';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    public langList$: BehaviorSubject<ILanguage[]> = new BehaviorSubject<ILanguage[]>([]);
    public currentLanguage$: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('lang') || 'en');
    public translationLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public translationError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private translateService: TranslateService,
        private http: HttpService,
        private toastService: ToastService,
        public authService: AuthService,
    ) {
        this.loadLanguages();
    }

    init(): void {
        const lang = localStorage.getItem('lang') || 'en';
        this.translateService.setDefaultLang(lang);
        this.setLanguage(lang);
    }

    public setLanguage(language: string): void {
        this.currentLanguage$.next(language);
        this.translateService.setDefaultLang(language);
        this.translateService.use(language)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.translationLoaded$.next(true);
                },
                error: () => {
                    this.translationError$.next(true);
                    this.toastService.showToastMsg('error',
                        this.translateService.instant('NoTranslationsFound'));
                    localStorage.setItem('lang', 'en');
                }
            });
        localStorage.setItem('lang', language);
    }

    public onSelectLanguage(payload): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.CHANGE_LANGUAGE,
            payload,
            false,
            null,
            true
        );
    }

    public localizeTableHeader(translationKey: string): string {
        return this.translateService.instant(translationKey);
    }

    public getPartnerLanguageSettings(id: number): Observable<IResponseData<IPartnerLanguages[]>> {
        const payload = {
            Controller: 'Language',
            Method: 'GetPartnerLanguageSettingsAllConfig',
            RequestObject:  id
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getPartnerLanguagesByState(partnerId: number, state: number = StatesEnum.Active): Observable<ICMSLanguage[]> {
        return this.http.request(
            'get',
            environment.CMSApiUrl + Methods.GET_LANGUAGES_BY_PARTNER_ID_AND_STATE,
            null,
            false,
            { params: { partnerId, state } },
            true
        );
    }

    public convertLanguage(languages: ICMSLanguage[], checkStatus = true): ILanguage[] {
        return languages
            .filter(language => !checkStatus || language.Status === StatesEnum.Active)
            .map(language => (
                    {
                        label: language.Name,
                        value: {
                            id: language.Id,
                            name: language.Name,
                            isRtl: language.IsRtl,
                        }
                    }
                )
            );
    }

    public changePartnerLanguageStatus(payload): Observable<any> {
        return this.http.request(
            'put',
            environment.CMSApiUrl + Methods.CHANGE_PARTNER_LANGUAGE_STATUS, payload
        );
    }

    public loadLanguages(): void {
        this.authService.userData$
            .pipe(filter(user => !!user))
            .subscribe(user => {
                if (localStorage.getItem('enum_languages')) {
                    this.langList$.next(JSON.parse(localStorage.getItem('enum_languages')));
                } else {
                    this.http.request(
                        'get',
                        environment.CMSApiUrl + Methods.GET_LANGUAGES
                    ).pipe(map(languages => this.convertLanguage(languages))
                    ).subscribe({
                        next: res => {
                            if (res) {
                                localStorage.setItem('enum_languages', JSON.stringify(res));
                                this.langList$.next(JSON.parse(localStorage.getItem('enum_languages')));
                            }
                        },
                        error: (err: HttpErrorResponse) => {
                            this.toastService.showToastMsg('error', err?.error?.message);
                        }
                    });
                }
            });
    }

    public updateLanguageList(languages: ICMSLanguage[]): void {
        const convertedLanguages = this.convertLanguage(languages);

        localStorage.setItem('enum_languages', JSON.stringify(convertedLanguages));
        this.langList$.next(convertedLanguages);
    }

    public getLanguages(): Observable<any[]> {
        return this.http.request(
            'get',
            environment.CMSApiUrl + Methods.GET_LANGUAGES
        );
    }
}
