import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@core/services/http.service';
import { environment } from '@env/environment';
import { Methods } from '@core/enums';
import {
    IGameProvider,
    IProduct,
    IResponseData,
    IResponseWithCount,
    IProductGroup,
    ISelectItem,
    IPlayerDetail,
    IPartner,
} from '@core/interfaces';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private noteStates: ISelectItem[] = [
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#EE6464',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Inactive',
            NickName: null
        }
    ];

    private notificationStates: ISelectItem[] = [
        {
            Background: '#e3e3e8',
            Color: '#b3b3bc',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Sent',
            NickName: null
        },
        {
            Background: '#EE6464',
            Color: '#3F3F3F',
            Id: 3,
            Info: null,
            Name: 'Failed',
            NickName: null
        }
    ];

    private partnerLanguageSettingsStates: ISelectItem[] = [
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#EE6464',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Inactive',
            NickName: null
        }
    ];

    private providersIRTSupportStates = [
        {
            Background: 'rgb(121, 242, 184)',
            Color: 'rgb(63, 63, 63)',
            Id: true,
            Info: null,
            Name: 'True',
            NickName: null
        },
        {
            Background: 'rgb(255, 214, 218)',
            Color: 'rgb(63, 63, 63)',
            Id: false,
            Info: null,
            Name: 'False',
            NickName: null
        }
    ];

    private KYCDocumentStates = [
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#EE6464',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Inactive',
            NickName: null
        }
    ];

    private partnerLimitsStates: ISelectItem[] = [
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#EE6464',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Inactive',
            NickName: null
        }
    ];

    private friendsStates: ISelectItem[] = [
        {
            Background: '#79F2B8',
            Color: '#3F3F3F',
            Id: 1,
            Info: null,
            Name: 'Active',
            NickName: null
        },
        {
            Background: '#EE6464',
            Color: '#3F3F3F',
            Id: 2,
            Info: null,
            Name: 'Inactive',
            NickName: null
        }
    ];

    public playerStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public noteStates$: BehaviorSubject<any> = new BehaviorSubject<any>(this.noteStates);
    public playerCategories$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public partners$: BehaviorSubject<IPartner[]> = new BehaviorSubject<IPartner[]>([]);
    public partnerStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public documentStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public documentTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public deviceTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public betTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public commonEnumModels$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public countries$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public cities$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public userStates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public userTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public gameProviders$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    // public subProviders$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public notificationStates$: BehaviorSubject<any> = new BehaviorSubject<any>(this.notificationStates);
    public productStates$: BehaviorSubject<any> = new BehaviorSubject([]);
    public productGroups$: BehaviorSubject<any> = new BehaviorSubject([]);
    public partnerLanguageSettingsStates$: BehaviorSubject<any> = new BehaviorSubject<any>(this.partnerLanguageSettingsStates);
    public partnerPaymentSettingStates$: BehaviorSubject<ISelectItem[]> = new BehaviorSubject([]);
    public bonusTypes$: BehaviorSubject<ISelectItem[]> = new BehaviorSubject<ISelectItem[]>([]);
    public bonusStates$: BehaviorSubject<ISelectItem[]> = new BehaviorSubject<ISelectItem[]>([]);
    public KYCDocumentStates$: BehaviorSubject<any> = new BehaviorSubject<any>(this.KYCDocumentStates);
    public partnerLimitsStates$: BehaviorSubject<ISelectItem[]> = new BehaviorSubject<ISelectItem[]>(this.partnerLimitsStates);
    public friendsStates$: BehaviorSubject<ISelectItem[]> = new BehaviorSubject<ISelectItem[]>(this.friendsStates);

    constructor(
        private http: HttpService,
    ) {
        this.loadPlayerStates();
        this.loadPlayerCategories();
        this.loadPartnerStates();
        this.loadCommonEnumModels();
        this.loadDocumentStatesEnumModels();
        this.loadDeviceTypes();
        this.loadBetTypes();
        this.loadRegions();
        this.loadUserStates();
        this.loadUserTypes();
        this.loadNotificationStates();
        // this.loadSubProviders();
        this.loadProductStates();
        this.loadProductGroups();
        this.loadPaymentSettingStates();
        this.loadBonusTypes();
        this.loadBonusStates();
        this.loadGameProvidersEnum();
    }

    private loadPlayerStates(): void {
        if (localStorage.getItem('enum_player-states')) {
            this.playerStates$.next(JSON.parse(localStorage.getItem('enum_player-states')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetClientStatesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Active' ? '#79F2B8' :
                                (status.Name === 'Blocked For Bet' || status.Name === 'Blocked For Deposit' || status.Name === 'Blocked For Withdraw') ? '#FFD6DA' :
                                    (status.Name === 'Force Block' || status.Name === 'Full Blocked') ? '#EE6464' : '#E3E3E8';

                            status.Color = status.Name === 'Active' ? '#3F3F3F' :
                                (status.Name === 'Blocked For Bet' || status.Name === 'Blocked For Deposit' || status.Name === 'Blocked For Withdraw') ? '#3F3F3F' :
                                    (status.Name === 'Force Block' || status.Name === 'Full Blocked') ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('enum_player-states', JSON.stringify(data.ResponseObject));
                        this.playerStates$.next(data.ResponseObject);
                    }
                });
        }
    }

    public loadUserTypes(): void {
        if (localStorage.getItem('enum_user-types')) {
            this.userTypes$.next(JSON.parse(localStorage.getItem('enum_user-types')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetUserTypesEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true
            )
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('enum_user-types', JSON.stringify(data.ResponseObject));
                        this.userTypes$.next(JSON.parse(localStorage.getItem('enum_user-types')));
                    }
                });
        }
    }

    public loadPartners(): void {
        if (localStorage.getItem('enum_partners')) {
            this.partners$.next(JSON.parse(localStorage.getItem('enum_partners')));
        } else {
            this.getPartnersList()
                .pipe(take(1))
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('enum_partners', JSON.stringify(data.ResponseObject));
                        this.partners$.next(data.ResponseObject);
                    }
                });
        }
    }

    public loadUserStates(): void {
        if (localStorage.getItem('enum_user-states')) {
            this.userStates$.next(JSON.parse(localStorage.getItem('enum_user-states')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetUserStatesEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Open' ? '#79F2B8' :
                                (status.Name === 'Closed' || status.Name === 'Locked' || status.Name === 'Blocked') ? '#FFD6DA' :
                                    (status.Name === 'Products' || status.Name === 'Force Block') ? '#EE6464' : '#E3E3E8';

                            status.Color = status.Name === 'Open' ? '#3F3F3F' :
                                (status.Name === 'Closed' || status.Name === 'Locked' || status.Name === 'Blocked') ? '#3F3F3F' :
                                    (status.Name === 'Products' || status.Name === 'Force Block') ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('enum_user-states', JSON.stringify(data.ResponseObject));
                        this.userStates$.next(data.ResponseObject);
                    }
                });
        }
    }

    public getPartners(filterOptions: any): Observable<any> {
        const payload = {
            Controller: 'Partner',
            Method: 'GetPartners',
            RequestObject: {
                ...filterOptions
            }
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public getPartnersList(): Observable<any> {
        const payload = {
            Controller: 'Partner',
            Method: 'GetPartnersList',
            RequestObject: {}
        };

        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    private loadPartnerStates(): void {
        if (localStorage.getItem('enum_partner-states')) {
            this.partnerStates$.next(JSON.parse(localStorage.getItem('enum_partner-states')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetPartnersStateEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Active' ? '#79F2B8' : '#EE6464';
                            status.Color = '#3F3F3F';
                        });
                        this.partnerStates$.next(data.ResponseObject);
                        localStorage.setItem('enum_partner-states', JSON.stringify(data.ResponseObject));
                    }
                });
        }
    }

    public loadProductStates(): void {
        if (localStorage.getItem('enum_product-states')) {
            this.productStates$.next(JSON.parse(localStorage.getItem('enum_product-states')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetProductStatesEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Active' ? '#79F2B8' : '#EE6464';
                            status.Color = '#3F3F3F';
                        });
                        this.productStates$.next(data.ResponseObject);
                        localStorage.setItem('enum_product-states', JSON.stringify(data.ResponseObject));
                    }
                });
        }
    }

    public loadDeviceTypes(): void {
        if (localStorage.getItem('enum_device-types')) {
            this.deviceTypes$.next(JSON.parse(localStorage.getItem('enum_device-types')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetDeviceTypesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('enum_device-types', JSON.stringify(data.ResponseObject));
                        this.deviceTypes$.next(data.ResponseObject);
                    }
                });
        }
    }

    public loadRegions(): void {
        if (localStorage.getItem('enum_countries')) {
            this.countries$.next(JSON.parse(localStorage.getItem('enum_countries')));
        } else {
            const payload = {
                Controller: 'Region',
                Method: 'GetRegions',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseObject) {
                        localStorage.setItem('enum_countries', JSON.stringify(data.ResponseObject));
                        this.countries$.next(data.ResponseObject.filter(country => country.TypeId === 5));
                        this.cities$.next(data.ResponseObject.filter(country => country.TypeId === 5));
                    }
                });
        }
    }

    public loadPlayerCategories(): void {
        if (localStorage.getItem('enum_player-categories')) {
            this.playerCategories$.next(JSON.parse(localStorage.getItem('enum_player-categories')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetClientCategoriesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('enum_player-categories', JSON.stringify(data.ResponseObject));
                        this.playerCategories$.next(data.ResponseObject);
                    }
                });
        }
    }

    public loadBetTypes(): void {
        if (localStorage.getItem('enum_bet-types')) {
            this.betTypes$.next(JSON.parse(localStorage.getItem('enum_bet-types')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetCreditDocumentTypesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('enum_bet-types', JSON.stringify(data.ResponseObject));
                        this.betTypes$.next(data.ResponseObject);
                    }
                });
        }
    }

    private loadCommonEnumModels(): void {
        if (localStorage.getItem('enum_common')) {
            this.commonEnumModels$.next(JSON.parse(localStorage.getItem('enum_common')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetCommonEnumModels',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe((res) => {
                    if (res?.ResponseCode === 0 && res.ResponseObject) {
                        localStorage.setItem('enum_common', JSON.stringify(res.ResponseObject));
                        this.commonEnumModels$.next(res.ResponseObject);
                    }
                });
        }
    }

    public loadDocumentStatesEnumModels(): void {
        if (localStorage.getItem('enum_document-states')) {
            this.documentStates$.next(JSON.parse(localStorage.getItem('enum_document-states')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetDocumenStatesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = (status.Name === 'Approved' || status.Name === 'Won' || status.Name === 'Returned') ? '#79F2B8' :
                                status.Name === 'Lost' ? '#EE6464' : status.Name === 'Rejected' ? '#808080' : '#E3E3E8';
                            status.Color = (status.Name === 'Approved' || status.Name === 'Won' || status.Name === 'Lost' ||
                                status.Name === 'Returned') ? '#3F3F3F' : status.Name === 'Rejected' ? '#E3E3E8' : '#B3B3BC';
                        });
                        localStorage.setItem('enum_document-states', JSON.stringify(data.ResponseObject));
                        this.documentStates$.next(data.ResponseObject);
                    }
                });
        }
    }

    public getDocumentTypes(playerId: number): void {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientDocumentTypes',
            RequestObject: playerId
        };

        this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true)
            .subscribe((res) => {
                if (res?.ResponseCode === 0 && res.ResponseObject) {
                    this.documentTypes$.next(res.ResponseObject);
                }
            });
    }

    public getPlayers(filterOptions: any): Observable<IResponseWithCount<IPlayerDetail>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClients',
            RequestObject: {
                ...filterOptions,
                // filterModel: {}
            }
        };
        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    public loadGameProvidersEnum(): void {
        if (localStorage.getItem('enum_game-providers')) {
            this.gameProviders$.next(JSON.parse(localStorage.getItem('enum_game-providers')));
        } else {
            this.getGameProvidersEnum()
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('enum_game-providers', JSON.stringify(data.ResponseObject));
                        this.gameProviders$.next(data.ResponseObject);
                    }
                });
        }
    }

    private getGameProvidersEnum(): Observable<IResponseData<any[]>> {
        const payload = {
            Controller: 'Product',
            Method: 'GetGameProvidersEnum',
            RequestObject: {}
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload
        );
    }


    public getGameProviders(request: object): Observable<IResponseWithCount<IGameProvider[]>> {
        const payload = {
            Controller: 'Product',
            Method: 'GetGameProviders',
            RequestObject: request
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }

    // public loadSubProviders(): void {
    //     if (localStorage.getItem('enum_sub-providers')) {
    //         this.subProviders$.next(JSON.parse(localStorage.getItem('enum_sub-providers')));
    //     } else {
    //         this.getSubProviders()
    //             .subscribe(data => {
    //                 if (data.ResponseCode === 0 && data.ResponseObject) {
    //                     localStorage.setItem('enum_sub-providers', JSON.stringify(data.ResponseObject));
    //                     this.subProviders$.next(data.ResponseObject);
    //                 }
    //             });
    //     }
    // }
    //
    // public getSubProviders(): Observable<IResponseData<IGameProvider[]>> {
    //     const payload = {
    //         Controller: 'Product',
    //         Method: 'GetSubProviders',
    //         RequestObject: {}
    //     };
    //     return this.http.request(
    //         'post',
    //         environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    // }

    private loadNotificationStates(): void {
        if (localStorage.getItem('enum_notification-states')) {
            this.notificationStates$.next(JSON.parse(localStorage.getItem('enum_notification-states')));
        } else {
            localStorage.setItem('enum_notification-states', JSON.stringify(this.notificationStates$.getValue()));
        }
    }

    public getProducts(req: { [key: string]: number } | any): Observable<IResponseWithCount<IProduct>> {
        const payload = {
            Controller: 'Product',
            Method: 'GetProducts',
            RequestObject: req
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload
        );
    }

    private loadProductGroups(): any {
        if (localStorage.getItem('enum_product-groups')) {
            this.productGroups$.next(JSON.parse(localStorage.getItem('enum_product-groups')));
        } else {
            this.getProductGroups()
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('enum_product-groups', JSON.stringify(data.ResponseObject));
                        this.productGroups$.next(data.ResponseObject);
                    }
                });
        }
    }


    public getProductGroups(): Observable<IResponseData<IProductGroup[]>> {
        const payload = {
            Controller: 'Product',
            Method: 'GetProductGroups',
            RequestObject: {}
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload
        );
    }

    public loadPaymentSettingStates(): void {
        if (localStorage.getItem('enum_partner-payment-setting')) {
            this.partnerPaymentSettingStates$.next(JSON.parse(localStorage.getItem('enum_partner-payment-setting')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetPaymentSettingStatesEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true
            )
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Active' ? '#79F2B8' : (status.Name === 'InActive' ? '#E3E3E8' : '#EE6464');
                            status.Color = '#3F3F3F';
                        });
                        localStorage.setItem('enum_partner-payment-setting', JSON.stringify(data.ResponseObject));
                        this.partnerPaymentSettingStates$.next(JSON.parse(localStorage.getItem('enum_partner-payment-setting')));
                    }
                });
        }
    }


    public loadBonusTypes(): void {
        if (localStorage.getItem('enum_bonus-types')) {
            this.bonusTypes$.next(JSON.parse(localStorage.getItem('enum_bonus-types')));
        } else {
            const payload = {
                Controller: 'Bonus',
                Method: 'GetBonusTypes',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true
            )
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        const bonusTypes: ISelectItem[] = data.ResponseObject.map(item => {
                            return {
                                Id: item.Value,
                                Info: null,
                                Name: item.Text,
                                NickName: null
                            };
                        });
                        localStorage.setItem('enum_bonus-types', JSON.stringify(bonusTypes));
                        this.bonusTypes$.next(JSON.parse(localStorage.getItem('enum_bonus-types')));
                    }
                });
        }
    }

    public loadBonusStates(): void {
        if (localStorage.getItem('enum_bonus-states')) {
            this.bonusStates$.next(JSON.parse(localStorage.getItem('enum_bonus-states')));
        } else {
            const payload = {
                Controller: 'Bonus',
                Method: 'GetBonusStatuses',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true
            )
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        const bonusStates: ISelectItem[] = data.ResponseObject.map(item => {
                            return {
                                Id: item.Value,
                                Info: null,
                                Name: item.Text,
                                NickName: null
                            };
                        });
                        localStorage.setItem('enum_bonus-states', JSON.stringify(bonusStates));
                        this.bonusStates$.next(JSON.parse(localStorage.getItem('enum_bonus-states')));
                    }
                });
        }
    }

    public getPartnerById(id: number): Observable<IResponseWithCount<IPartner>> {
        const payload = {
            Controller: 'Partner',
            Method: 'GetPartners',
            RequestObject: { id }
        };

        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload);
    }
}
