import { ColDef } from 'ag-grid-community';

export function generateColumnDefs(name: string, columnDefs: ColDef[]): ColDef[] {
    const savedStateStr = localStorage.getItem(`column-state_${name}`);
    if (savedStateStr) {
        const savedState = JSON.parse(savedStateStr);
        const newState = [];
        savedState.forEach(state => {
            const identifier = state.field || state.colId;
            const column = columnDefs.find(c => c.field === identifier || c.colId === identifier);
            if (column) {
                newState.push({ ...column, ...state, sort: null });
            }
        });
        return newState;
    }
    return columnDefs;
}
